/* Dropdown Styles */
.search-form .dropdown {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px; /* Adjust based on your preference */
  overflow-y: auto;
  position: absolute;
  top: 100%; /* Position it right below the search input */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it's on top */
}

.search-form .dropdown-item {
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-form .dropdown-item:last-child {
  border-bottom: none;
}

.search-form .dropdown-item:hover {
  background-color: #f7f7f7;
}

.logo_home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure it takes the full width of its parent */
}

.logoholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoholder img {
  width: 100%; /* Adjust this value to decrease or increase the size of the logo */
  max-width: 500px; /* Optional: set a maximum width for the logo */
  max-height: 300px;
}

/* Center the toggle button div */
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack the text and the button vertically */
  margin-top: 20px; /* Add some margin to the top for spacing */
}

/* Style the "Toggle dark mode" text */
.toggle-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px; /* Add some margin to the bottom for spacing between the text and the button */
  color: var(--font-color); /* Use the font color variable for the text color */
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* When the checkbox is checked, add a blue background */
input:checked + .slider {
  background-color: var(--button-bg-color);
}

/* Move the slider to the right when checked */
input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Define CSS custom properties for light and dark themes */
:root {
  /* Light theme colors */
  --background-color: #ececec;
  --font-color: #3b3939;
  --font-color-secondary: #868484;
  --font-color-heading: #010101;
  --border-color: #d5d5d5;
  --input-placeholder-color: #8d8d8d;
  --input-focus-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.06);
  --button-bg-color: #002664;
  --button-bg-color-hover: rgba(0, 38, 100, 0.85);
  --breadcrumbs-color: #575656;
  --breadcrumbs-separator-color: #575656;
  --card-bg-color: #ffffff;
  --card-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  --sidebar-bg-color: #dbdbdb;
  --sidebar-border-color: #b2b2b2;
  --sidebar-link-hover-color: #26c8fe;
  --sidebar-colorlink: black;
  --sidebar-current-item-border-color: #e42313;
  --sidebar-current-item-color: #e42313;
  --icon-filter: none; /* No filter for light mode */
}

body.dark-mode {
  --background-color: #505050; /* Retained as per your request */
  --font-color: #aeaeae; /* Neutral font color for better readability */
  --font-color-secondary: #888888; /* Slightly lighter secondary font color for less emphasis */
  --font-color-heading: #ffffff; /* White color for headings for contrast */
  --border-color: #3a3a3a; /* Slightly darker border color for subtle borders */
  --input-placeholder-color: #888888; /* Slightly lighter placeholder color for a cohesive look */
  --input-focus-shadow: 0 0 9px 1px rgba(255, 255, 255, 0.1); /* Existing focus shadow for emphasis */
  --button-bg-color: #a9425e; /* Neutral color for buttons */
  --button-bg-color-hover: rgba(
    174,
    174,
    174,
    0.8
  ); /* Slight transparency on hover for the same color */
  --breadcrumbs-color: #c0c0c0; /* Lighter breadcrumbs color */
  --breadcrumbs-separator-color: #c0c0c0; /* Matching separator color */
  --card-bg-color: #272727; /* Very dark background for cards */
  --card-shadow: 0 0 6px rgba(0, 0, 0, 0.2); /* Slightly more pronounced card shadow for depth */
  --sidebar-bg-color: #3a3a3a; /* Slightly darker sidebar background for contrast */
  --sidebar-border-color: #4d4d4d; /* Existing lighter sidebar border */
  --sidebar-colorlink: #aeaeae; /* Neutral color for sidebar links */
  --sidebar-current-item-color: #f7f7f7; /* Retained bright color for active sidebar items */
  --sidebar-link-hover-color: #a9425e; /* Bright color for sidebar links on hover */
  --documentation-links-h2: #bdbdbd;
  --bread-links-li: #da5477;
  --icon-filter: invert(1); /* Invert colors for dark mode */
}

.white-icon {
  filter: var(--icon-filter);
}

/* The switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* h1 styling */
.h1,
h1 {
  font-size: calc(1.5rem + 1.5vw);
  color: var(--font-color-heading);
  font-weight: 600;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body {
  background-color: var(--background-color);
  font-family: "Switzer", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
@media screen and (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
  .h3,
  h3 {
    font-size: 1.75rem;
  }
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
@font-face {
  font-family: "Switzer";
  src: url("./webfonts/switzer/Switzer-Medium.woff2") format("woff2"),
    url("./webfonts/switzer/Switzer-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Switzer";
  src: url("./webfonts/switzer/Switzer-Semibold.woff2") format("woff2"),
    url("./webfonts/switzer/Switzer-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Switzer";
  src: url("./webfonts/switzer/Switzer-Regular.woff2") format("woff2"),
    url("./webfonts/switzer/Switzer-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.container,
.container-fluid {
  margin: 0 auto;
  padding: 0 20px;
}
.container {
  max-width: 1320px;
}
.container-fluid {
  max-width: 1470px;
}
.anchor-overlay {
  font-size: 0;
  inset: 0;
  position: absolute;
  z-index: 1;
}
.search-form form {
  height: 50px;
  position: relative;
}
.search-form input[type="text"] {
  border: 0.5px solid var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 100%;
  display: block;
  font-size: 1rem;
  padding: 0 60px 0 15px;
  width: 100%;
}
.search-form input[type="text"]::-webkit-input-placeholder {
  color: #8d8d8d;
  opacity: 1;
}
.search-form input[type="text"]::-moz-placeholder {
  color: #8d8d8d;
  opacity: 1;
}
.search-form input[type="text"]:-ms-input-placeholder {
  color: #8d8d8d;
  opacity: 1;
}
.search-form input[type="text"]::-ms-input-placeholder {
  color: #8d8d8d;
  opacity: 1;
}
.search-form input[type="text"]::placeholder {
  color: var(--input-placeholder-color);
  opacity: 1;
}
.search-form input[type="text"]:focus {
  -webkit-box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--input-focus-shadow);
  outline: none;
}
.search-form input[type="submit"] {
  background-color: var(--button-bg-color);
  background-image: url(./images/magnify-glass.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 3px;
  bottom: 3px;
  cursor: pointer;
  font-size: 0;
  position: absolute;
  -webkit-transition: 300ms background-color linear;
  transition: 300ms background-color linear;
  right: 3px;
  top: 3px;
  width: 50px;
}
.search-form input[type="submit"]:hover {
  background-color: var(--button-bg-color-hover);
}
.breadcrumbs {
  color: var(--font-color-secondary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 0;
  padding-left: 0;
}
.breadcrumbs li:not(:last-of-type):after {
  content: "/";
  color: var(--breadcrumbs-separator-color);
  margin: 0 5px;
}
.breadcrumbs a {
  color: var(--breadcrumbs-color);
  text-decoration: none;
}
.breadcrumbs li {
  color: var(--bread-links-li);
  text-decoration: none;
}
.breadcrumbs a:hover {
  color: #000000;
}
.text-content {
  color: var(--font-color);
}

.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5,
.text-content h6 {
  color: var(--font-color-heading);
  font-weight: 500;
}
@media screen and (min-width: 576px) {
  .search-form:not(.documentation-sidebar__form) form {
    height: 68px;
  }
  .search-form:not(.documentation-sidebar__form) input[type="text"] {
    padding: 0 75px 0 22px;
  }
  .search-form:not(.documentation-sidebar__form) input[type="submit"] {
    bottom: 6px;
    width: 58px;
    right: 9px;
    top: 6px;
  }
}
.site-header {
  margin: 0 0 30px;
  padding: 20px 0;
}
@media screen and (min-width: 576px) {
  .site-header {
    margin: 0 0 9px;
    padding: 45px 0;
  }
}
.search-helper {
  text-align: center;
}
.search-helper__inner {
  max-width: 690px;
  margin: 0 auto;
}
.search-helper .search-form {
  margin: 20px 0 0;
}
@media screen and (min-width: 576px) {
  .search-helper .search-form {
    margin: 32px 0 0;
  }
}
@media screen and (min-width: 992px) {
  .search-helper h2 {
    font-size: 44px;
  }
}
.documentation-links {
  margin: 30px 0;
}
.documentation-links__inner > .row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
}
.documentation-links__single {
  background-color: var(--card-bg-color);
  box-shadow: var(--card-shadow);
  border-radius: 15px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 45px 32px;
  position: relative;
  -webkit-transition: 300ms -webkit-box-shadow linear;
  transition: 300ms -webkit-box-shadow linear;
  transition: 300ms box-shadow linear;
  transition: 300ms box-shadow linear, 300ms -webkit-box-shadow linear;
}
.documentation-links__single:hover {
  -webkit-box-shadow: 0 16px 64px -16px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 64px -16px rgba(0, 0, 0, 0.2);
}
.documentation-links__icon {
  margin: 0 0 33px;
}
.documentation-links__content > h2 {
  color: var(--documentation-links-h2);
  font-size: 22px;
}
.documentation-links__content > p {
  color: #888888;
  margin-right: -10px;
}
.documentation-links__content > p:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 576px) {
  .documentation-links {
    margin: 60px 0;
  }
  .documentation-links__inner > .row {
    -ms-grid-columns: 1fr 38px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 38px;
  }
  .documentation-links__icon {
    margin: 0 0 53px;
  }
  .documentation-links__content > h2 {
    margin: 0 0 16px;
  }
}
@media screen and (min-width: 992px) {
  .documentation-links {
    margin: 150px 0 60px;
  }
  .documentation-links__inner > .row {
    -ms-grid-columns: 1fr 38px 1fr 38px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1200px) {
  .documentation-links__inner > .row {
    -ms-grid-columns: 1fr 38px 1fr 38px 1fr 38px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.documentation-sidebar {
  z-index: 10;
  background-color: var(--sidebar-bg-color);
  bottom: 0;
  left: 0;
  min-height: 100vh;
  padding: 20px;
  max-width: 327px;
  overflow-y: auto;
  position: fixed;
  scrollbar-width: none;
  -ms-overflow-style: none;
  top: 0;
  -webkit-transform: translateX(-327px);
  transform: translateX(-327px);
  -webkit-transition: 300ms -webkit-transform linear;
  transition: 300ms -webkit-transform linear;
  transition: 300ms transform linear;
  transition: 300ms transform linear, 300ms -webkit-transform linear;
  width: 100%;
}
.documentation-sidebar__toggle.absolute {
  position: absolute;
  right: 10px; /* Adjust this value based on where you want the button to appear */
  top: 10px; /* Adjust this value based on where you want the button to appear */
}
.documentation-sidebar__toggle img {
  transition: transform 0.3s ease; /* Smooth transition for the rotation effect */
}

.documentation-sidebar__toggle.absolute img {
  transform: rotate(180deg); /* Rotate the image by 180 degrees */
}

.documentation-sidebar::-webkit-scrollbar {
  width: 0;
}
.documentation-sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.documentation-sidebar__logo {
  margin-bottom: 50px;
}
.documentation-sidebar__toggle {
  z-index: 1;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  position: fixed;
  -webkit-transform: translate(5px, 10px) rotate(0);
  transform: translate(5px, 10px) rotate(0);
  -webkit-transition: 300ms -webkit-transform linear;
  transition: 300ms -webkit-transform linear;
  transition: 300ms transform linear;
  transition: 300ms transform linear, 300ms -webkit-transform linear;
}
.documentation-sidebar__menu {
  margin-top: 50px;
  padding-left: 20px;
}
.documentation-sidebar__menu ul {
  padding-left: 0;
  list-style-type: none;
}
.documentation-sidebar__menu > ul {
  font-weight: 500;
  font-size: 18px;
}
.documentation-sidebar__menu > ul ul {
  border-left: 1.5px solid var(--sidebar-border-color);
  color: var(--font-color-secondary);
  font-size: 16px;
  font-weight: normal;
  margin-top: 30px;
  padding-left: 18px;
}
.documentation-sidebar__menu .menu-item {
  color: var(--sidebar-colorlink);
  margin-bottom: 30px;
  position: relative;
}
.documentation-sidebar__menu .menu-item:hover > a {
  color: var(--sidebar-link-hover-color);
}
.documentation-sidebar__menu .current-menu-item:before {
  border-left: 2px solid var(--sidebar-current-item-border-color);
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
}
.documentation-sidebar__menu .current-menu-item > a {
  color: var(--sidebar-current-item-color);
}
.documentation-sidebar__menu a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 300ms color linear;
  transition: 300ms color linear;
}
.documentation-sidebar.is-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.documentation-sidebar.is-open ~ .documentation-sidebar__toggle {
  -webkit-transform: translate(275px, 20px) rotate(-180deg);
  transform: translate(275px, 20px) rotate(-180deg);
}

/* Default Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dark Mode Scrollbar Styles */
body.dark-mode::-webkit-scrollbar-track {
  background: #2a2a2a;
  border-radius: 5px; /* Rounded corners for the track in dark mode */
}

body.dark-mode::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 5px; /* Rounded corners for the thumb in dark mode */
}

body.dark-mode::-webkit-scrollbar-thumb:hover {
  background: #aeaeae;
}

@media screen and (min-width: 1200px) {
  .documentation-sidebar {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    padding: 45px 20px;
  }
  .documentation-sidebar__logo {
    margin-bottom: 70px;
    padding-left: 30px;
  }
  .documentation-sidebar__toggle {
    display: none;
  }
  .documentation-sidebar__menu {
    margin-top: 90px;
  }
}
.documentation-content {
  padding: 50px 20px 20px 20px;
  max-width: 1140px;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .documentation-content {
    margin-left: 327px;
    padding: 100px;
    width: calc(100% - 327px);
  }
}

/* Dropdown Styles */
.search-form .dropdown {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px; /* Adjust based on your preference */
  overflow-y: auto;
  position: absolute;
  top: 100%; /* Position it right below the search input */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it's on top */
}

.search-form .dropdown-item {
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-form .dropdown-item:last-child {
  border-bottom: none;
}

.search-form .dropdown-item:hover {
  background-color: #f7f7f7;
}

.logo-card {
  display: flex; /* Make this a flex container */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */

  margin-right: 30px;
}

.logo-card img {
  display: block;
  max-width: 125%; /* Reduce to 90% to ensure it fits within the container */
  height: auto;
}
